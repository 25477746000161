@import 'mixins';
@import 'theme';

// $foreground: map-get($theme, foreground);

html,
body {
  @include flex-column;
  font-size: 14px;
  font-family: 'Muli', sans-serif;
  // color: mat-color($foreground, text, 0.87);
  margin: 0;

  & > main {
    @include flex-column;
  }
}

[dir='rtl'] .mat-icon {
  transform: scale(-1, 1);
}

.adf-container-full-width {
  overflow-x: hidden;
}

app-root,
app-about,
adf-layout-container,
aca-search-results,
ng-component {
  @include flex-column;
}

a {
  text-decoration: none;
  color: #005fb2;
}

a:hover {
  // color: mat-color(map-get($theme, primary));
}

main {
  background-color: #f4f4f4;
}

.dialog-responsive {
  width: 40%;
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
.mat-progress-bar-buffer {
  background-color: #f1f1f1;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.mat-form-field-invalid .mat-mdc-floating-label mat-label {
  color: #f44336;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-underline {
  background-color: #f44336;
}
// underline
// .mat-mdc-form-field.mat-form-field-invalid .mat-mdc-form-field-infix {
//   border-bottom: 2px solid #f44336;
// }

.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--invalid
  .mdc-line-ripple::before {
  border-bottom-color: #f44336 !important;
}

.mat-mdc-form-field-infix {
  min-height: auto !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy .mat-form-field-ripple {
  display: none !important;
}

.fan__gl_btn {
  width: auto;
  float: left;
  border: solid 1px #01579b !important;
  color: #01579b !important;
  border-radius: 0px !important;
  min-width: auto !important;
  line-height: 1.57 !important;
  font-size: 0.8em;
  padding: 0.5em !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy .mat-form-field-ripple {
  display: none !important;
}

.fan__gl_btn-1 {
  transform: scale(0.9);
  transform-origin: left;

  /* TODO (mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-mdc-button-wrapper {
    // color: mat-color(map-get($theme, primary));
  }
}

.fan__gl_btn-2 {
  transform: scale(0.9);
  transform-origin: left;
  background-color: #6250b4 !important;

  /* TODO (mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-mdc-button-wrapper {
    color: #fff !important;
  }
}

.error-snackbar {
  // background-color: #f4433645;
  background-color: #faa19b;
  opacity: 90% !important;

  .mat-mdc-snackbar-surface {
    background-color: transparent !important;
  }

  .mat-mdc-simple-snack-bar span,
  .mat-mdc-snack-bar-label {
    font-weight: 600;
    color: #a94442 !important;
  }

  .mat-mdc-snack-bar-action {
    color: white !important;
  }
}

.erroring-snackbar {
  background-color: #fa4a3d;

  .mat-mdc-simple-snack-bar span {
    font-weight: 600;
    color: #8f0806 !important;
  }

  .mat-mdc-snack-bar-action {
    color: white !important;
  }
}

.success-snackbar {
  background-color: #dff0d8;
  opacity: 90% !important;

  .mat-mdc-snackbar-surface {
    background-color: transparent !important;
  }

  span,
  .mat-mdc-simple-snack-bar span,
  .mat-mdc-snack-bar-label {
    font-weight: 600;
    color: #3c763d !important;
  }
}

.loading-snackbar {
  background-color: #2b2b2b;

  span {
    color: #ffffff !important;
  }
}

.fl {
  float: left;
}

.fan__tc_hc {
  background-color: #ffffff;
  width: 100%;
  float: left;
  padding: 0.5em 0em 1em 0em;
  box-sizing: border-box;
}

.fan__tc_head {
  font-size: 1.4em;
  font-weight: bold;
  line-height: 2.5;
  color: #444444;
}

.fan__tc_txt {
  font-size: 1em;
  line-height: 1.29;
  color: #6c6c6c;
  width: 100%;
  float: left;
  padding-bottom: 1em;
}

.fan__tc_body {
  background-color: #f4f4f4;
  width: 100%;
  float: left;
  height: 100%;
}

.fan__gl_c {
  width: 100%;
  float: left;
}

.fan__gl_p {
  margin: 0em 2em;
  box-sizing: border-box;
}

.fan__gl_mid {
  width: 80%;
  margin: 0 auto;
}

.fan__tc_filter {
  float: left;
  font-size: 1.1em;
  font-weight: bold;
  color: #6250b4;
  cursor: pointer;

  img {
    margin-top: -9px;
    margin-left: 2em;
  }
}

.viewport {
  width: 100%;
  height: calc(100% - 3.75rem);
  position: absolute;
}

.amplify-alert {
  margin-top: 1.5em !important;
}

.mat-mdc-header-cell {
  color: #444444 !important;
  font-size: 1em;
  font-weight: 600 !important;
}

mat-header-row {
  background-color: #fafaf9 !important;
}

mat-header-row,
mat-footer-row,
mat-row {
  max-height: 100%;
  min-height: 2.5em !important;
}

.dialog-table-title {
  height: 26px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.67;
  text-align: center;
  color: #36373a;
  padding-bottom: 0.75em;
}

.mat-mdc-cell,
.mat-mdc-footer-cell {
  font-size: 1em !important;
  line-height: 2.14 !important;
  color: #080707 !important;
  white-space: inherit;
}
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 0 !important;
}

.trainer-table-data {
  font-size: 1em;
  line-height: 2.14;
  color: #080707;
}

.mat-mdc-table {
  background: white;
}

.custom-label {
  font-size: 1em;
  font-weight: 600;
  line-height: 2.31;
  color: #514f4d;
  float: left;
}

.finalised {
  font-weight: 600;
  line-height: 2.14;
  font-size: 1em;
}

.finalised-yes {
  color: #3d8c75 !important;
}

.finalised-no {
  color: #e0592a !important;
}

.fan__gl_bc_header {
  font-family: Muli;
  width: 100%;
  float: left;
  padding: 0.75em 0em;
}

.fan__gl_bc_item {
  float: left;
  list-style: none;
  height: 24px;
  line-height: 24px;
  padding: 0.25em 0.25em 0.25em 0em;
  cursor: pointer;
}

.fan__gl_bc_item a {
  text-decoration: none;
  color: #36373a;
}

.fan__gl_bc_active {
  font-weight: 600;
  color: #36373a;
  text-decoration: none;
}

.fan__gl_bc_right {
  float: right;
  list-style: none;
}

.fan__gl_div {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
}

.fan__gl_l30 {
  width: 30%;
  float: left;
  box-sizing: border-box;
}

.fan__gl_l33 {
  width: 33%;
  float: left;
  box-sizing: border-box;
}

.fan__gl_l50 {
  width: 50%;
  float: left;
  box-sizing: border-box;
}

.fan__gl_l66 {
  width: 66%;
  float: left;
  box-sizing: border-box;
}

.fan__gl_r70 {
  width: 70%;
  float: left;
  box-sizing: border-box;
}

.fan__gl_icon {
  display: inline-block;
  color: #6250b4;
  cursor: pointer;
  font-weight: 600;
  margin-left: -0.5em;
}

.fan__gl_icon1 {
  display: inline-block;
  color: #6250b4;
  cursor: pointer;
  font-weight: 600;
  margin-left: -0.5em;
}

.fan__gl_icon > a > mat-icon {
  cursor: pointer;
  vertical-align: text-bottom;
  transform: scale(0.8);
  transform-origin: bottom;
  color: #6250b4;
}

.fan__gl_icon > span {
}

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
mat-chip {
  border-radius: 4px !important;
  background-color: #ff9800 !important;
  color: white !important;
  font-weight: 600;
}

/* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version.*/
mat-paginator {
  position: relative !important;
  background-color: #f4f4f4;

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-legacy,
  .mat-form-field-underline {
    height: 0px !important;
  }

  .mat-mdc-paginator-page-size-label {
    margin: 1em !important;
  }

  .mat-mdc-paginator-page-size {
    width: 500px;
    display: inherit;
    position: absolute;
    left: 0px;
  }
}

.mat-mdc-paginator {
  background-color: #f4f4f4 !important;
  color: #080707 !important;
  font-size: 1em;
}

.icon-in-box {
  transform: scale(0.8);
  transform-origin: center;
}

.fan__gl_l50_l {
  display: flex;
  flex: 1;
  width: 50%;
  margin-right: 0.5em;
}

.fan__gl_l50_r {
  display: flex;
  flex: 1;
  width: 50%;
  margin-right: 0.5em;
}

.dialog-responsive1 {
  width: 30%;
}

@media screen and (max-width: 1940px) and (min-width: 1840px) {
  app-trainerconnect-dashboard {
    // .fan__gl_btn-2 {
    //   width: 208px;
    //   height: 60px;
    //   border-radius: 30px;
    //   box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
    //   background-color: #6250b4;
    // }
  }

  .dialog-responsive {
    width: 30%;
  }
}

@media screen and (max-width: 720px) {
  .dialog-responsive {
    width: 80%;
  }

  .dialog-responsive1 {
    width: 80%;
  }
}

@media only screen and (max-width: 1440px) {
  :root {
    --component-width-desktop: 360px;
  }

  .amplify-form-input {
    width: 280px !important;
    height: 36px !important;
  }

  .amplify-alert {
    width: 360px !important;
  }
}

p {
  height: auto !important;
}

.amplify-form-actions {
  margin-top: 1em !important;
  margin-bottom: 1.5em !important;
}

button[data-test='sign-in-sign-in-button'] {
  // background: mat-color(map-get($theme, primary));
}

button[data-test='sign-in-sign-in-button']:hover {
  // background: mat-color(map-get($theme, primary));
  opacity: 0.9;
}

.trainer-table-data1x {
  font-family: Muli;
  font-size: 14px;
  line-height: 2.14;
  color: #080707;
}

.page-header {
  text-align: center;
}

app-trainerconnect-dashboard {
  a {
    text-decoration: none;
    // color: #005fb2;
  }

  .trainer-table-data1x {
    font-family: Muli;
    font-size: 14px;
    line-height: 2.14;
    color: #080707;
  }
}

// 11-11-2024
.mat-mdc-select-panel {
  padding: 0 !important;
  max-width: 280px;
  max-height: 256px;
  font-size: 14px;
  opacity: 1;
  min-width: calc(100% + 32px);
  transform: scaleY(1);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  -webkit-overflow-scrolling: touch;
}
// .mat-mdc-select-panel .mat-mdc-option {
//   font-size: inherit;
//   line-height: 3em;
//   height: 3em;
// }
.mat-mdc-option .mat-pseudo-checkbox-minimal {
  margin-left: 0 !important;
  flex-shrink: 0;
  display: none;
}
.mat-mdc-unelevated-button > .mat-icon {
  font-size: 24px !important;
  height: 24px !important;
  width: 24px !important;
}
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

mat-cell:first-of-type,
mat-header-cell:first-of-type,
mat-footer-cell:first-of-type {
  padding-left: 24px !important;
}
mat-cell:last-of-type,
mat-header-cell:last-of-type,
mat-footer-cell:last-of-type {
  padding-right: 24px !important;
}

// 12-11-2024

// Select Panel Outline
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mat-mdc-notch-piece {
  border-color: rgba(0, 0, 0, 0.12) !important;
}
// PopUp Padding
.mat-mdc-dialog-surface {
  padding: 24px !important;
}
// Input Bg Color
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: white !important;
}
// Input Padding
.mdc-text-field {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

// Contact Popup
.fan__gl_btn-1.mdc-button .mdc-button__label {
  color: gray;
}

// Global Popup
.mat-mdc-dialog-container .mat-mdc-dialog-content,
.mat-mdc-dialog-container .mat-mdc-dialog-title + .mat-mdc-dialog-content {
  padding: 0 !important;
  margin: 0 !important;
}
.mat-mdc-dialog-container .mat-mdc-dialog-content {
  color: rgba(0, 0, 0, 0.87) !important;
}
.mat-mdc-dialog-container-with-actions .mat-mdc-dialog-content {
  padding: 0 !important;
}
.mat-mdc-raised-button .mdc-button__label {
  color: #fff !important;
}
.mat-mdc-dialog-container .mat-mdc-dialog-title {
  margin: 0 0 20px !important;
  display: block;
  font: 500 20px / 32px Roboto, 'Helvetica Neue', sans-serif !important;
  letter-spacing: normal !important;
}
.mat-mdc-form-field-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-top: -0.25em;
}

// Contact us Popup - Send button
.mat-mdc-dialog-container
  .fan__gl_btn-2.mat-mdc-unelevated-button
  .mdc-button__label {
  color: #fff !important;
}

// Dropdown
.mdc-list-item__primary-text {
  display: inline-block;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-gray) !important;
}
.mat-mdc-option {
  line-height: 3em !important;
}
// Logout
.mat-mdc-menu-item-text {
  background: transparent;
  color: rgba(0, 0, 0, 0.87) !important;
}
div#mat-menu-panel-0,
.mat-mdc-menu-panel {
  /* margin-left: 50px; */
  position: absolute;
  right: 60px;
}
.mat-elevation-z8,
.mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

