/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
// @import '~@alfresco/adf-core/prebuilt-themes/adf-blue-orange.css';
@import 'app/ui/application';
@import '../node_modules/@aws-amplify/ui/src/Theme.css';
@import '../node_modules/@aws-amplify/ui/src/Angular.css';
//  @import "~@angular/material/prebuilt-themes/indigo-pink.css";
body,
html {
  height: 100%;
  font-family: 'Muli', 'Helvetica', 'Arial', sans-serif !important;
}

body {
  margin: 0;
}

* {
  font-size: 14px;
  font-family: Muli;
}
